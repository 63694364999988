import { Trans } from '@lingui/macro';
import Layout from 'components/Layout';
import { PrivacyPolicySection } from 'components/PrivacyPolicySection/PrivacyPolicySection';
import Seo from 'components/SEO';
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <Layout contactForm={false}>
      <Seo title="Fractal Cloud" />

      <section id="privacy-policy-section" className="hero-wrapper">
        <div className="hero">
          <div className="hero__content">
            <h1 className="hero__title">
              <Trans>Cookie Policy</Trans>
            </h1>
            <p className="hero__text">
              <Trans>
                This Privacy Policy will help you better understand how we collect, use and share
                your personal information
              </Trans>
            </p>
          </div>
        </div>
      </section>

      <PrivacyPolicySection />
    </Layout>
  );
};

export default PrivacyPolicy;
